// work data

export const Work = [
  {
    id: 1,
    name: "The SuperStar Fam",
    description: "It is Non-Fun",
    tags: ["React", "Ethereum", "Web3", "Express Js"],

    demo: "https://thesuperstarfam.com/",
    github: "",
  },
  {
    id: 2,
    name: "COMPUTER SOCIETY OF INDIA- SVVV WEBSITE",
    description: "Developed community website using ReactJS",
    tags: ["React", "Firebase", "Bootstrap"],

    demo: "http://www.csi-sb.svvv.edu.in/",
    github: "",
  },

  {
    id: 3,
    name: "Happy Freelancer",
    description:
      "A Freelancing website which help a freelancer and recruiter to communitcate",
    tags: ["Django", "Django", "Sqlite", "Heroku"],

    demo: "https://quiet-dusk-23748.herokuapp.com/",
    github: "",
  },
];
